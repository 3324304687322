@import "../../../styles/mixins";

.button {
  border: none;
  cursor: pointer;
  background: var(--primary-gradient);
  border-radius: 0.5rem;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: var(--primary-text-color);
  padding: 0.625rem 2.75rem;
  border: 0.0625rem solid var(--primary-hover-border-color);

  @include tablet {
    font-size: 14px;
    line-height: 20px;
  }

  &:hover {
    background: var(--primary-gradient-hover);
    border: 0.0625rem solid var(--primary-hover-border-color);
  }

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  &_secondary {
    background: var(--secondary-gradient);
    border: none;

    &:hover {
      background: var(--secondary-hover-color);
      border: none;
    }
  }

  &_small {
    padding: 0.5rem 1rem;

    @include tablet {
      padding: 0.3rem 0.5rem;
    }
  }
}
