@import "../../styles/mixins";

.home {
  background-image: url("../../assets/images/home-bg.png");
  background-size: cover;
  background-position: 0 -5.25rem;

  @include tablet {
    background-image: url("../../assets/images/home-bg-mobile.png");
    background-position: 0 -100px;
  }
}
