.form {
  padding-top: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  position: relative;

  & > input {
    margin-top: 2rem;
  }
}

.title {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2.25rem;
  text-align: center;
}

.description {
  margin-top: 0.75rem;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
}

.btn {
  margin-top: auto;
}

.formError {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 1.125rem;
  color: var(--error-color);
  text-align: center;
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;

  & .title {
    margin-bottom: 1.5rem;
  }
}

.closeBtn {
  position: absolute;
  top: 0.5rem;
  right: 0.8rem;
  background: transparent;
  border: none;
  cursor: pointer;
  color: var(--primary-text-color);
  font-size: 1.5rem;
  line-height: 1.5rem;
}
