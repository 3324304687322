@import "@styles/mixins";

.result {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 18.75rem;
  padding: 2.25rem 0.875rem 1.5rem 0.875rem;

  @include tablet {
    height: 400px;
    padding: 24px;
    padding-top: 36px;
  }
}

.title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.25rem;
  text-align: center;
  color: #9fff24;

  &_lose {
    color: var(--negative-color);
  }
}

.picksWrapper {
  margin-top: 1.125rem;
  display: grid;
  grid-template-columns: 1fr 2.5rem 1fr;

  @include tablet {
    display: block;
    width: 100%;
    margin-bottom: 12px;
  }
}

.picksDivider {
  height: 100%;
  width: 0.0625rem;
  background-color: #fff;
  margin: 0 auto;

  @include tablet {
    display: none;
  }
}

.picks {
  & p {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.125rem;
  }
}

.tokens {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;

  & > div {
    margin-right: -1.3rem;

    @include tablet {
      &:not(:last-child) {
        margin-right: 12px;
      }
    }

    & div {
      display: none;
    }
  }
}

.total {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 0.0625rem solid #fff;
  padding-top: 0.375rem;
  margin-top: 0.375rem;
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1.125rem;
}

.btn {
  display: block;
  margin: 0 auto;
  margin-top: auto;
}
