.modal {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  opacity: 1;
  transition: 0.4s;
  background-color: #1717178f;
  z-index: 100;
}

.modalContent {
  background: var(--primary-gradient);
  border-radius: 0.5rem;
  z-index: 2;
  width: 21.25rem;
  height: 18.125rem;
  max-height: 80vh;
  transition: 0.4s;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 1.5rem;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 105%;
    height: 6.0625rem;
    background: var(--secondary-gradient);
    transform-origin: top left;
    transform: rotate(5deg);
    z-index: -1;
  }

  &_secondary {
    background: var(--secondary-gradient);

    &::after {
      background: var(--primary-gradient);
    }
  }
}
