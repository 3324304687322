@import "@styles/mixins";

.how {
  padding-bottom: 7.5rem;

  @include tablet {
    padding-bottom: 84px;
  }
}

.cardsWrapper {
  margin-top: 4.375rem;
  display: grid;
  grid-template-columns: 15rem 15rem 31rem 15rem;
  height: 20rem;
  justify-content: space-between;

  @include tablet {
    display: none;
  }

  & img {
    height: 100%;
  }
}

.textWrapper {
  margin-top: 1.125rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 3.125rem;
  padding-right: 3.8rem;

  @include tablet {
    display: none;
  }

  & h4 {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.125rem;
    text-align: center;
  }
}

.textDivider {
  width: 6rem;
  height: 0.125rem;
  background: var(--secondary-gradient);
  border-radius: 0.0625rem;
}

.textMultiple {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 26rem;

  @include tablet {
    width: 100%;
  }

  & > span {
    width: 0.25rem;
    height: 0.25rem;
    border-radius: 0.25rem;
    background: var(--secondary-gradient);

    @include tablet {
      margin-top: 12px;
    }
  }
}

.mobileSlider {
  display: none;
  margin-top: 28px;
  margin-left: -15px;
  padding-left: 15px;
  padding-right: 15px;
  width: calc(100% + 30px);

  @include tablet {
    display: block;
  }
}

.mobileCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 165px;

  &:nth-child(3) {
    width: 345px;
  }

  & img {
    max-height: 220px;
    width: unset;
  }

  & h4 {
    margin-top: 12px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }
}
