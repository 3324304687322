@import "@styles/mixins";

.search {
  padding-bottom: 3rem;
  margin-top: -2rem;

  @include tablet {
    padding-bottom: 42px;
    margin-top: 0;
  }
}

.logo {
  display: block;
  width: 25.3rem;
  margin: 0 auto;

  @include tablet {
    width: 166px;
  }
}

.cardsWrapper {
  margin-top: 2.25rem;
  display: grid;
  grid-template-columns: repeat(3, auto);
  column-gap: 2.875rem;
  justify-content: center;

  @include tablet {
    display: none;
  }
}

.mobileSlider {
  display: none;
  margin-top: 36px;

  & h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 48px;
    text-align: center;
  }

  @include tablet {
    display: block;
  }
}

:global {
  .swiper {
    margin-top: 12px;
  }

  .swiper-pagination-bullets {
    display: grid;
    grid-template-columns: repeat(3, auto);
    column-gap: 20px;
    justify-content: center;
    top: 0;
    bottom: unset !important;
  }

  .swiper-wrapper {
    margin-top: 66px;
  }
}

.mobileBullet {
  width: 2.5rem;
  height: 2.5rem;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--primary-hover-border-color);
  cursor: pointer;

  &_active {
    opacity: 1;
    background: var(--primary-gradient);
  }
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;

  & p {
    margin-top: 0.75rem;
    font-size: 1.4rem;
    line-height: 2rem;
  }
}
