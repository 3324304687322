@import "../../../../styles/mixins";

.faq {
  padding: 0 17.875rem 7.5rem 17.875rem;

  @include tablet {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 120px;
  }
}

.wrapper {
  margin-top: 3.75rem;
}

.item {
  padding: 1.125rem 0 1.125rem 0.75rem;
  border-top: 0.0625rem solid #ccc;

  @include tablet {
    padding: 12px 0;
  }

  &:last-child {
    border-bottom: 0.0625rem solid #ccc;
  }
}

.itemHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  & > h4 {
    font-family: "a Abstract Groovy";
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2rem;

    @include tablet {
      font-size: 18px;
      line-height: 32px;
    }
  }

  & > span {
    background-image: url("../../../../assets/icons/plus.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 1rem;
    height: 1rem;
  }
}

.itemHeaderOpened {
  & h4 {
    background: var(--primary-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  & span {
    background-image: url("../../../../assets/icons/minus.svg");
  }
}

.itemText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin-top: 0.75rem;

  & a {
    color: inherit;
  }

  @include tablet {
    margin-top: 12px;
    font-size: 12px;
    line-height: 18px;
  }
}
