@import "@styles/mixins";

.main {
  width: 27.8125rem;

  @include tablet {
    width: 100%;

    & > h3 {
      display: none;
    }
  }
}

.searchWrapper {
  margin-top: 1.375rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include tablet {
    margin-top: 43px;
  }

  & > span {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    flex-shrink: 0;
  }
}

.searchInput {
  width: 11.375rem;
  max-width: 11.375rem;
  border: none;
  background: transparent;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  border-bottom: 0.0625rem solid var(--primary-text-color);
  color: var(--primary-text-color);
  background-image: url("../../../../assets/icons/search-icon.svg");
  background-repeat: no-repeat;
  background-position: 0 center;
  padding-left: 1.375rem;

  @include tablet {
    width: 100%;
  }

  &:focus {
    outline: none;
  }
}

.pickWrapper {
  margin-top: 0.75rem;
  display: grid;
  grid-template-rows: 1fr;
  row-gap: 0.5rem;
  max-height: 17.5rem;
  overflow-y: auto;
  padding-bottom: 1rem;
}
