@import "@styles/mixins";

.game {
  @include tablet {
    margin-top: 33px;
    width: 100%;
    flex-grow: 1;
  }
}

.logo {
  width: 13.75rem;
  display: block;
  margin: 0 auto;

  @include tablet {
    display: none;
  }
}

:global(.groovyTitle) {
  @include tablet {
    display: none;
  }
}

.wrapper {
  margin-top: 2.25rem;
  display: flex;
  justify-content: center;
  height: 24.375rem;

  @include tablet {
    margin-top: 10px;
    height: auto;
    flex-direction: column;
  }
}

.styledBlock {
  border-radius: 0.5rem;
  padding: 1.5rem;
  background: var(--third-gradient);
  border: 0.0625rem solid #02959f;

  @include tablet {
    padding: unset;
    background: unset;
    border: unset;
    width: 100%;
  }

  &_padSmall {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
}

.rightSide {
  margin-left: 1.5625rem;
  width: 19.4375rem;
  display: flex;
  flex-direction: column;

  @include tablet {
    display: none;
  }

  & > div:nth-child(1) {
    padding: 1rem;
    padding-left: 1.5rem;
  }

  & > div:nth-child(2) {
    margin-top: 1.1875rem;
    flex-grow: 1;
  }
}

.prizeWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.withMusicWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include tablet {
    display: none;
  }

  &_showMobile {
    display: none;

    @include tablet {
      display: flex;
    }
  }
}

.mobileBlock {
  display: none;

  @include tablet {
    display: block;
  }
}

.mobileRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobileTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 3rem;
  display: none;

  @include tablet {
    display: block;
  }
}

.hideInMobile {
  @include tablet {
    display: none !important;
  }
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & p {
    margin-top: 0.75rem;
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
}
