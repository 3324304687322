.item {
  display: grid;
  grid-template-columns: 1.125rem 1fr 3.75rem 3.5rem;
  padding: 0.625rem;
  background: radial-gradient(50% 392% at 73.1% 82%, rgba(57, 160, 31, 0.1) 0%, rgba(15, 15, 16, 0.1) 100%),
    linear-gradient(97.27deg, #231f1f 0%, #070b10 100%);
  border: 0.0625rem solid #272727;
  border-radius: 0.3125rem;
  cursor: pointer;
  transition: border 0.15s;

  &:hover {
    border: 0.0625rem solid #79331d;
  }

  &_negative {
    background: radial-gradient(50% 392% at 73.1% 82%, rgba(255, 80, 26, 0.1) 0%, rgba(15, 15, 16, 0.1) 100%),
      linear-gradient(97.27deg, #231f1f 0%, #070b10 100%);
  }

  &_disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.icon {
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 50%;
}

.name {
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin-left: 0.625rem;
}

.change {
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.125rem;
  background-image: url("../../../../assets/icons/up-change.svg");
  background-position: 0 40%;
  background-repeat: no-repeat;
  padding-left: 0.75rem;
  color: var(--positive-color);

  &_negative {
    background-image: url("../../../../assets/icons/down-change.svg");
    background-position: 0 center;
    color: var(--negative-color);
  }
}

.currency {
  // margin-left: 1.125rem;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.125rem;
  text-align: right;
}
