.change {
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: var(--positive-color);
  display: flex;
  align-items: center;
  justify-content: center;

  &_negative {
    color: var(--negative-color);
  }

  & > svg {
    margin-right: 0.1875rem;
    width: 0.5rem;
  }
}
