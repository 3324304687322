@import "@styles/mixins";

.menu {
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;

  @include tablet {
    flex-direction: column;
    font-size: 24px;
    line-height: 48px;
  }

  & img {
    width: 1.5rem;
    margin-right: 0.75rem;

    @include tablet {
      margin-right: 0;
      width: 60px;
      margin-bottom: 12px;
    }
  }
}

.list {
  margin-top: 1.125rem;
  display: grid;
  grid-template-rows: 1fr;
  row-gap: 0.75rem;

  & > p {
    font-style: normal;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.5rem;
    color: rgba(255, 255, 255, 0.5);

    @include tablet {
      font-size: 24px;
      line-height: 32px;
    }
  }
}

.btn {
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #fff;

  @include tablet {
    font-size: 24px;
    line-height: 32px;
  }

  &:hover {
    color: #ff501a;

    & > svg path {
      fill: #ff501a;
    }
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  & > svg {
    width: 0.875rem;
    margin-right: 0.75rem;

    @include tablet {
      width: 24px;
      margin-right: 18px;
    }
  }
}
