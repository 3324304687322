:root {
  --primary-gradient: linear-gradient(315deg, #fd6205 0%, #f08204 100%);
  --primary-gradient-hover: #ffac4a;

  --secondary-gradient: linear-gradient(225deg, #02959f 0%, #00c8d5 100%);
  --third-gradient: radial-gradient(50% 392% at 73.1% 82%, rgba(2, 149, 159, 0.2) 0%, rgba(15, 15, 16, 0.2) 100%),
    linear-gradient(97.27deg, #0f0f0f 0%, #070b10 100%);
  --fourth-gradient: radial-gradient(50% 392% at 73.1% 82%, rgba(255, 80, 26, 0.1) 0%, rgba(15, 15, 16, 0.1) 100%),
    linear-gradient(97.27deg, #0f0f0f 0%, #070b10 100%);

  --primary-color: ;
  --primary-hover-color: ;

  --primary-text-color: #fff;
  --primary-hover-text-color: ;

  --primary-border-color: #494949;
  --primary-hover-border-color: #ef8303;

  --secondary-color: #02959f;
  --secondary-hover-color: #02959f;

  --secondary-text-color: ;
  --secondary-hover-text-color: ;

  --secondary-border-color: #ed7c04;
  --secondary-hover-border-color: ;

  --error-color: #ff0000;

  --positive-color: #00ef8b;
  --negative-color: #e84142;
}

[data-theme="dark"] {
  --primary-color: ;
  --primary-hover-color: ;

  --primary-text-color: ;
  --primary-hover-text-color: ;

  --primary-border-color: ;
  --primary-hover-border-color: ;

  --secondary-color: ;
  --secondary-hover-color: ;

  --secondary-text-color: ;
  --secondary-hover-text-color: ;

  --secondary-border-color: ;
  --secondary-hover-border-color: ;
}
