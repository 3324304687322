.itemWrapper {
}

.item {
  background: radial-gradient(50% 392% at 73.1% 82%, rgba(255, 80, 26, 0.1) 0%, rgba(15, 15, 16, 0.1) 100%),
    linear-gradient(97.27deg, #0f0f0f 0%, #070b10 100%);
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.625rem;
  height: 2.625rem;
  padding: 0.4375rem;
  position: relative;
  border: 0.0625rem solid #ed7c04;

  &:hover {
    background: linear-gradient(0deg, rgba(173, 173, 173, 0.2), rgba(173, 173, 173, 0.2)),
      radial-gradient(50% 392% at 73.1% 82%, rgba(255, 80, 26, 0.1) 0%, rgba(15, 15, 16, 0.1) 100%),
      linear-gradient(97.27deg, #0f0f0f 0%, #070b10 100%);
    border-color: #f08204;

    & .closeBtn {
      opacity: 1;
    }
  }

  &_empty {
    pointer-events: none;

    &::before {
      content: "";
      position: absolute;
      width: 1.75rem;
      height: 1.75rem;
      background-color: #342729;
      border-radius: 50%;
    }
  }

  &_hidden {
    border-color: #79331d;

    &::before {
      content: "";
      position: absolute;
      width: 1.75rem;
      height: 1.75rem;
      background: url("../../../../assets/icons/hidden-token-icon.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  &_positive {
    border-color: var(--positive-color);
    pointer-events: none;
  }

  &_negative {
    border-color: var(--negative-color);
    pointer-events: none;
  }
}

.closeBtn {
  background: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  width: 1rem;
  height: 1rem;
  background: var(--primary-gradient);
  border-radius: 50%;
  font-size: 0.875rem;
  line-height: 0.875rem;
  color: #fff;
  opacity: 0;
  transition: opacity 0.15s;
}

.itemChange {
  margin-top: 0.375rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.125rem;
  text-align: center;
}
