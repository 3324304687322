@import "../../../../styles/mixins";

.adv {
  padding-left: 4.5rem;
  padding-right: 4.5rem;
  padding-bottom: 7.5rem;

  @include tablet {
    padding-left: 68px;
    padding-right: 68px;
    padding-bottom: 84px;
  }
}

.wrapper {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 1.5rem;

  @include tablet {
    margin: 0 auto;
    margin-top: 48px;
    grid-template-columns: none;
    grid-template-rows: 1fr;
    row-gap: 36px;
    width: 240px;
  }
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  & img {
    width: 4rem;
  }

  & p {
    margin-top: 1.5rem;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.125rem;
    text-align: center;
  }
}
