@import "@styles/mixins";

.prizePool {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  text-transform: uppercase;

  & span {
    margin-left: 0.5rem;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    @include secondaryGradientText;
  }
}
