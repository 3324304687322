@import "@styles/mixins";

.card {
  width: 16.125rem;
  height: 22.0625rem;
  background: var(--primary-gradient);
  border-radius: 0.5rem;
  padding: 1.75rem 1.5rem 2rem 1.5rem;
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 105%;
    height: 7.5625rem;
    background: var(--secondary-gradient);
    z-index: -1;
    border-bottom: 0.0625rem solid var(--primary-border-color);
    transform-origin: bottom left;
    transform: rotate(-4deg);
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 105%;
    height: 7.125rem;
    background: var(--secondary-gradient);
    transform-origin: top left;
    transform: rotate(5deg);
    z-index: -1;
    border-top: 0.0625rem solid var(--primary-border-color);
  }

  &_disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.duration {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.25rem;
  text-align: center;
  color: #000;
  text-transform: uppercase;

  & > span {
    font-weight: 700;
    @include gradientText;
  }
}

.descr {
  margin-top: 0.25rem;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.125rem;
  text-align: center;
  color: #000;

  & > span {
    font-weight: 700;
    @include gradientText;
  }
}

.rangeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 1.375rem;

  & > p {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  & span {
    font-style: normal;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.125rem;
  }
}

.rangeMarks {
  margin-top: 0.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // margin-left: -0.3rem;
  // margin-right: -0.3rem;

  & > span {
    cursor: pointer;
    padding: 0 0.1rem;
  }
}

.rangeInput {
  margin-top: 0.375rem;
  margin-bottom: 0.75rem;
  width: 97%;
  height: auto;
  align-self: flex-start;
}

:global {
  & .rc-slider-rail {
    background: #d2d2d2;
    height: 0.0625rem;
    border-radius: 0.0625rem;
    cursor: pointer;
  }

  & .rc-slider-track {
    background: var(--secondary-gradient);
    border-radius: 0.0625rem;
    height: 0.0625rem;
    cursor: pointer;
  }

  & .rc-slider-handle {
    background: var(--secondary-gradient);
    width: 0.5625rem;
    height: 0.5625rem;
    border: 0.125rem solid #758958;
    margin-top: -0.25rem;

    &-dragging {
      box-shadow: none !important;
    }
  }
}

.btn {
  display: block;
  margin: 0 auto;
}

.loaderWrapper {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > span {
    margin-left: 15px;
    margin-bottom: 6px;
  }
}

.error {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 1.125rem;
  color: var(--error-color);
}
