@import "@styles/mixins";

.footer {
  padding: 0.75rem 4rem;
  background: radial-gradient(50% 392% at 73.1% 82%, rgba(92, 56, 238, 0.1) 0%, rgba(15, 15, 16, 0.1) 100%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    linear-gradient(97.27deg, #0f0f0f 0%, #070b10 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include tablet {
    background: unset;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0;
    padding-top: 20px;
  }
}

.logo {
  width: 3.75rem;

  @include tablet {
    display: none;
  }
}

.copyright {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.75rem;

  @include tablet {
    font-size: 12px;
    line-height: 14px;
    text-align: center;
  }
}

.social {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1.125rem;

  @include tablet {
    margin-top: 12px;
  }

  & > a {
    width: 1.5rem;

    @include tablet {
      width: 28px;
    }
  }
}

.homeIndicator {
  display: none;
  width: 134px;
  height: 5px;
  background-color: #fff;
  border-radius: 100px;
  margin-bottom: 38px;

  @include tablet {
    display: block;
  }
}
