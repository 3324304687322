@import "../../../../styles/mixins";

.promo {
  padding: 3.75rem 11rem;

  @include tablet {
    padding: 178px 0 84px 0;
    margin: 0 22px;
    margin-top: 0;
    position: relative;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include tablet {
    justify-content: center;
  }
}

.title {
  margin-right: 6.4375rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include tablet {
    margin-right: 0;
  }

  & > img {
    width: 13.875rem;

    @include tablet {
      width: 223px;
    }
  }

  & h2 {
    margin-top: 1.75rem;

    @include tablet {
      margin-top: 47px;
      max-width: 300px;
    }
  }
}

.chartsImg {
  width: 33.3125rem;

  @include tablet {
    position: absolute;
    top: 48px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 330px;
    opacity: 0.5;
    z-index: -1;
  }
}

.tryBtn {
  display: block;
  margin: 0 auto;
  margin-top: 3rem;

  @include tablet {
    margin-top: 60px;
  }
}
