@import "@styles/mixins";

.drafts {
  display: flex;
  flex-direction: column;
  height: 100%;

  @include tablet {
    margin-top: 18px;
    height: 216px;
    justify-content: space-between;
  }
}

.choiceTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--primary-text-color);
}

.tokensWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &_mt {
    margin-top: 0.75rem;
  }

  & > div:not(:last-child) {
    margin-right: 0.8125rem;
  }
}

.opponent {
  margin-top: auto;

  & > p {
    margin-top: 0.375rem;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.125rem;
  }
}

.mobileRow {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > h3 {
    display: none;
  }

  @include tablet {
    & > h3 {
      display: block;
    }
  }
}

.totalWrapper {
  margin-top: 0.375rem;
  padding-top: 0.375rem;
  border-top: 0.0625rem solid rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1.125rem;

  @include tablet {
    border-top: unset;
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    display: block;
    padding-left: 16px;
    margin-top: 0;
    padding-top: 30px;
  }
}

.infoWrapper {
  margin-top: 0.75rem;

  @include tablet {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
  }
}
