@import-normalize;
@import "reset-css";

@import "swiper/css";
@import "swiper/css/pagination";
@import "rc-slider/assets/index.css";

@import "./mixins";

@import "./constants.scss";
@import "./fonts.scss";
@import "./titles.scss";
@import "./inputs.scss";

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 0.25rem;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background: var(--secondary-gradient);
  border-radius: 0.125rem;
}

html {
  font-size: 16px;

  @media (max-width: 1441px) {
    font-size: 1.08vw;
  }

  @include tablet {
    font-size: 16px;
  }
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  font-size: 1rem;
  color: #fff;
  background: radial-gradient(38.82% 109.45% at 80.49% 61.18%, rgba(160, 61, 160, 0.2) 0%, rgba(0, 0, 0, 0) 100%),
    radial-gradient(34.08% 120.29% at 16.49% 34.08%, rgba(179, 74, 61, 0.2) 0%, rgba(0, 0, 0, 0) 100%), #000000;
}

img {
  width: 100%;
}

.container {
  max-width: min(100%, 1440px);
  margin: 0 auto;
  padding: 0 4rem;

  @include tablet {
    padding: 0 15px;
  }
}

.global-bg {
  background-image: url("../assets/images/global-bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include tablet {
    background-image: url("../assets/images/global-bg-mobile.svg");
  }
}
