.timer {
  width: 4.375rem;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  padding: 0.5rem;
  background: var(--secondary-gradient);
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #fff;
}
