@import "../../../../styles/mixins";

.what {
  padding-bottom: 7.5rem;

  @include tablet {
    padding-bottom: 84px;
  }

  & h3 {
    text-align: left;

    @include tablet {
      text-align: center;
    }
  }
}

.whatIs {
  display: flex;
  align-items: center;
  justify-content: center;

  @include tablet {
    padding-left: 16px;
    padding-right: 16px;
  }

  & > img {
    width: 19.5rem;
    margin-right: 3rem;

    @include tablet {
      display: none;
    }
  }

  & > div {
    max-width: 19.375rem;

    @include tablet {
      max-width: unset;
    }
  }
}

.whatWeDo {
  margin-top: 3.75rem;
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    width: 33.5625rem;

    @include tablet {
      display: none;
    }
  }

  & > div {
    max-width: 21.0625rem;
    margin-right: 3rem;

    @include tablet {
      max-width: unset;
      margin-right: 0;
    }
  }
}

.text {
  margin-top: 1.125rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;

  @include tablet {
    margin-top: 18px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
}

.whatIsMobileImg {
  display: none;
  margin: 0 auto;
  margin-top: 32px;
  max-width: 345px;

  @include tablet {
    display: block;
  }
}
